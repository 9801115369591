// Enable SVG <use xlink:href> support in IE11
import svg4everybody from "svg4everybody";
svg4everybody();

// Import each icon that we want to include in our SVG sprite
// (built by Webpack + svg-sprite-loader)
import "./arrow1.svg";
import "./arrow2.svg";
import "./arrow3a.svg";
import "./arrow3b.svg";
import "./arrow3c.svg";
import "./arrow-backtotop.svg";
import "./arrow-short.svg";
import "./cross.svg";
import "./email.svg";
import "./facebook.svg";
import "./gallimard-teaching.svg";
import "./heart.svg";
import "./instagram.svg";
import "./logo.svg";
import "./menu.svg";
import "./quote.svg";
import "./search.svg";
import "./twitter.svg";
import "./youtube.svg";
import "./trash.svg";
