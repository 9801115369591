// ========================
// BookItem component
// ========================

import $ from 'jquery';
import removeFromWishList from '../../../scripts/main/wishlist/wishlist'

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    $('.BookItem').on('click', function (event) {
        removeFromWishList(event.target);
    })
}
