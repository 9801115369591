// =================
// Popup
// =================

const closePopup = document.querySelector(".Popup-close");
const popUp = document.querySelector(".Popup");



if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init)
} else {
    init()
}

function init() {
    'use strict';

    closePopup.addEventListener("click", function () {
        popUp.classList.add('Popup--isHidden');
    });


}

