// ============
// Header hover
// ============

import $ from 'jquery';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    const search = $('[data-search-dropdown]');
    const header = $('.Header'),
        headerSearch = $('.Header-searchContainer label'),
        headerSearchButton = $('.Header-searchButton'),
        headerSearchPanel = $('.Header-search .Header-searchPanel'),
        headerSearchInput = $('.Header-search input'),
        headerLink1 = $('.Header-links li:nth-child(1) a'),
        headerLink2 = $('.Header-links li:nth-child(2) a'),
        headerLink3 = $('.Header-links li:nth-child(3) a'),
        headerLink4 = $('.Header-links li:nth-child(4) a');

    if (search.length === 0) {
        return;
    }

    function openSearchPanel() {
        search.show('fast', function () {
            search.children().slideDown(400);

            if (window.matchMedia('(max-width: 700px)').matches) {
                $('body').addClass('Locked');
            }

        });
    }

    function closeSearchPanel(search) {
        search.hide('fast', function () {
            search.children().slideUp(300);

            if (window.matchMedia('(max-width: 700px)').matches) {
                $('body').removeClass('Locked');
            }

        });
    }

    let open = false;
    $(document)
        .on('click', function (e) {
            if (open && $(e.target).closest(".Header-search").length === 0) {
                closeSearchPanel(search);
                open = false;
            }
        })
        .on('click', '.Header-searchContainer--fromTablet', function () {
            if (!open) {
                openSearchPanel(search);
                open = true;
            }
        })
        .on('click', '.Header-searchContainer--phone', function () {
            if (!open) {
                openSearchPanel(search);
                open = true;
            } else {
                closeSearchPanel(search);
                open = false;
            }
        });


    headerSearch.mouseenter(function() { header.addClass('Header--hoverSearch') });
    headerSearch.mouseleave(function() { header.removeClass('Header--hoverSearch') });

    headerSearchButton.mouseenter(function() { header.addClass('Header--hoverSearch') });
    headerSearchButton.mouseleave(function() { header.removeClass('Header--hoverSearch') });

    headerLink1.mouseenter(function() { header.addClass('Header--hoverLink1') });
    headerLink1.mouseleave(function() { header.removeClass('Header--hoverLink1') });

    headerLink2.mouseenter(function() { header.addClass('Header--hoverLink2') });
    headerLink2.mouseleave(function() { header.removeClass('Header--hoverLink2') });

    headerLink3.mouseenter(function() { header.addClass('Header--hoverLink3') });
    headerLink3.mouseleave(function() { header.removeClass('Header--hoverLink3') });

    headerLink4.mouseenter(function() { header.addClass('Header--hoverLink4') });
    headerLink4.mouseleave(function() { header.removeClass('Header--hoverLink4') });

    headerSearchInput.focus(function() {
        header.addClass('Header--activeSearch')
    });

    // Active detection
    const headerSearchCallback = function(mutationsList) {
        for(const mutation of mutationsList) {
            headerSearchInput.blur(function() {
                if (!$(this).val()) {
                    header.removeClass('Header--activeSearch')
                } else{
                    header.addClass('Header--activeSearch')
                }
            });
        }
    };

    if (headerSearch.length > 0) {
        const observer = new MutationObserver(headerSearchCallback);
        observer.observe(headerSearch.get(0), {attributeFilter: ['aria-expanded']});
    }


    let text = null;
    let timeout = null;
    let xhr = null;
    let searchUrl = headerSearchInput.data('search-url');

    headerSearchInput.on('keyup', function (event) {
        let _text = $(this).val().trim();
        if (_text.length <= 2 || _text === text) {
            return;
        }

        text = _text;

        if (timeout) {
            clearTimeout(timeout);
            timeout = false;
        }

        if (xhr) {
            xhr.abort();
            xhr = false;
        }

        timeout = setTimeout(function(){
            xhr = $.ajax(searchUrl, {cache: true, data: {text: text}})
                .done(function (html) {
                    if (!open) {
                        openSearchPanel(search);
                        open = true;
                    }
                    headerSearchPanel.find('ul.SearchPanel').replaceWith(html);
                    header.addClass('Header--activeSearch');
                })
                .fail(function () {
                    // header.removeClass('Header--activeSearch'); // Todo: check how searchbar render
                    headerSearchPanel.find('ul.SearchPanel').empty();
                });
        }, 100);
    });
}

