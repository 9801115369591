// =================
// Homepage Networks
// =================

import $ from 'jquery';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    const newsletterInput = $('[data-newsletter-input]');
    const newsletterWarning = $('[data-newsletter-display]');

    newsletterInput.on('input', newsletterWarning.addClass('display'))
}
